
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class MainWithSidePanelLayout extends Vue {
  @Prop({ type: Boolean }) readonly flipColors!: boolean
  @Prop({ type: Number, default: 404 }) readonly sideContentWidth!: number
  @Prop({ type: Number, default: 84 }) readonly sideContentTopPadding!: number

  get isSectionImageSlotPresent(): any {
    return this.$slots?.['section-image']
  }
}
