var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"padding-a-0",class:{
    'fill-height': _vm.$vuetify.breakpoint.mdAndUp,
  },attrs:{"fluid":""}},[_c('v-row',{class:{ 'fill-height': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"no-gutters":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{staticClass:"flex-shrink-0 padding-x-8 border-r-1 border-l-0 border-y-0 border-solid border-border-gray",class:{
        'background-header-gray': _vm.flipColors,
        'background-white': !_vm.flipColors,
      },style:({
        width: `${_vm.sideContentWidth}px`,
        'max-width': `${_vm.sideContentWidth}px`,
        'padding-top': `${_vm.sideContentTopPadding}px`,
        'box-sizing': 'border-box',
      })},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex align-center margin-y-2"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSectionImageSlotPresent),expression:"isSectionImageSlotPresent"}]},[_vm._t("section-image")],2),_c('h3',{style:({ 'margin-left': '10px' })},[_vm._t("section-title")],2)]),_c('v-col',{key:"side-navigation",attrs:{"cols":"12"}},[_vm._t("navigation")],2)],1)],1):_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"padding-x-6",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSectionImageSlotPresent),expression:"isSectionImageSlotPresent"}],attrs:{"cols":"auto"}},[_vm._t("section-image")],2),_c('v-col',{staticClass:"margin-y-3"},[_c('h1',[_vm._t("section-title")],2)]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('CUIcon',_vm._g(_vm._b({staticClass:"kebab-button margin-t-n2",attrs:{"id":"sidebar-menu-mobile","view-box":"0 0 24 24"}},'CUIcon',attrs,false),on),[_vm._v(" more_vert ")])]}}])},[_vm._t("navigation")],2)],1)],1),_c('v-col',{staticClass:"flex-shrink-1",class:{
        'padding-a-6': _vm.$vuetify.breakpoint.smAndUp,
        'padding-a-0': _vm.$vuetify.breakpoint.xs,
        'background-white': _vm.flipColors || _vm.$vuetify.breakpoint.xsOnly,
        'background-header-gray': !_vm.flipColors && !_vm.$vuetify.breakpoint.xsOnly,
      }},[_c('v-container',{class:{
          'padding-t-0': _vm.$vuetify.breakpoint.smAndDown,
        }},[_vm._t("default")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }